.site-layout-background {
  background: #fff;
  padding: 0;
  min-height: 80vh;
}

.header {
  display: flex;
  flex-direction: row-reverse;
  padding-top: 15px;

  &-button {
    margin-left: 20px;
  }

}

.logo {
  position: absolute;
  height: 50px;
  width: 50px;
  margin: left;
  margin-top: 10px;
  // padding-right: 15px;
}
