.login {
  background-image: url("../../assets/img/png/background.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  &-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
  }

  &-form {
    background: #fff;
    padding: 24px;
    min-height: 200px;
    border-radius: 5px 5px;
  }
}
